
































































































import Vue from "vue";
import { DateTime } from "luxon";
import { Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Role, AdminUser } from "@/core/models";

@Component
export default class UserItem extends Vue {
  @Prop({ default: () => {} }) user!: AdminUser;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => false }) isLast!: boolean;
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Getter("profile/isAdmin") isAdmin!: boolean;
  @Getter("profile/getUserHasPrivileges") getUserHasPrivileges!: boolean;

  get userRoles() {
    return this.user.roles?.sort((a: any, b: any) =>
      b.name.localeCompare(a.name),
    );
  }

  get userIcon() {
    if (this.user.roles?.some(r => r.name === Role.Admin))
      return "mdi-shield-account";
    else return "mdi-account";
  }

  get activeUntil() {
    if (!this.user.activeUntil) return "";
    const date = DateTime.fromJSDate(new Date(this.user.activeUntil), {
      locale: this.$i18n.locale,
      // @ts-ignore
    }).toLocaleString(DateTime.DATETIME_MED);
    return ` ${this.$t("activeUser.until")} ${date} UTC`;
  }

  get canManage() {
    if (this.user.email === this.currentUserEmail) return false;
    return this.isAdmin || this.getUserHasPrivileges;
  }

  get isMe() {
    return this.user.email === this.currentUserEmail;
  }
}
